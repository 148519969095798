var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "mt-3 p-2" },
    [
      _c(
        "form",
        {
          attrs: { name: "editForm", novalidate: "", role: "form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.saveAllCategories()
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex justify-content-between" }, [
            _c("h4", { staticClass: "mb-2" }, [_vm._v("Kategorien")]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", disabled: !_vm.isAdmin() },
                on: {
                  click: function ($event) {
                    return _vm.addCategory()
                  },
                },
              },
              [_vm._v("\n        Kategorie hinzufügen\n      ")]
            ),
          ]),
          _vm._v(" "),
          !_vm.isLoading
            ? _c("div", [
                _vm.categories && _vm.categories.length > 0
                  ? _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _vm._l(_vm.categories, function (cat, index) {
                          return _c(
                            "div",
                            {
                              key: index + "_cat",
                              staticClass: "align-items-start mt-3",
                            },
                            [
                              cat
                                ? _c(
                                    "div",
                                    { staticClass: "row mb-0 border-bottom" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-1 d-flex flex-column",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-sm m-0 p-0",
                                              attrs: {
                                                disabled:
                                                  index === 0 || !_vm.isAdmin(),
                                                type: "button",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateOrder(
                                                    cat,
                                                    -1
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "chevron-up" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn btn-sm m-0 p-0",
                                              attrs: {
                                                disabled:
                                                  index ===
                                                    _vm.categories.length - 1 ||
                                                  !_vm.isAdmin(),
                                                type: "button",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateOrder(cat, 1)
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: { icon: "chevron-down" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-group mb-0 col-5",
                                          staticStyle: { flex: "1" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.$v.categories.$each[index]
                                                    .description.$model,
                                                expression:
                                                  "$v.categories.$each[index].description.$model",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              valid:
                                                !_vm.$v.categories.$each[index]
                                                  .description.$invalid,
                                              invalid:
                                                _vm.$v.categories.$each[index]
                                                  .description.$invalid,
                                            },
                                            attrs: {
                                              placeholder: "Bezeichnung",
                                              required: "",
                                              type: "text",
                                              disabled: !_vm.isAdmin(),
                                            },
                                            domProps: {
                                              value:
                                                _vm.$v.categories.$each[index]
                                                  .description.$model,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.$v.categories.$each[index]
                                                    .description,
                                                  "$model",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.$v.categories.$each[index]
                                            .description.$anyDirty &&
                                          _vm.$v.categories.$each[index]
                                            .description.$invalid
                                            ? _c("div", [
                                                !_vm.$v.categories.$each[index]
                                                  .description.required
                                                  ? _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "form-text text-danger",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  Pflichtfeld.\n                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-group mb-0 col-5",
                                          staticStyle: { flex: "1" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.number",
                                                    value:
                                                      _vm.$v.categories.$each[
                                                        index
                                                      ].weight.$model,
                                                    expression:
                                                      "$v.categories.$each[index].weight.$model",
                                                    modifiers: { number: true },
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: {
                                                  valid:
                                                    !_vm.$v.categories.$each[
                                                      index
                                                    ].weight.$invalid,
                                                  invalid:
                                                    _vm.$v.categories.$each[
                                                      index
                                                    ].weight.$invalid,
                                                },
                                                attrs: {
                                                  disabled: !_vm.isAdmin(),
                                                  placeholder: "Gewichtung",
                                                  required: "",
                                                  type: "number",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.$v.categories.$each[
                                                      index
                                                    ].weight.$model,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.$v.categories.$each[
                                                        index
                                                      ].weight,
                                                      "$model",
                                                      _vm._n(
                                                        $event.target.value
                                                      )
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.$v.categories.$each[index]
                                                .weight.$anyDirty &&
                                              _vm.$v.categories.$each[index]
                                                .weight.$invalid
                                                ? _c("div", [
                                                    !_vm.$v.categories.$each[
                                                      index
                                                    ].weight.required
                                                      ? _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "form-text text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    Pflichtfeld.\n                  "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.$v.categories.$each[
                                                      index
                                                    ].weight.min
                                                      ? _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "form-text text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    Bitte geben Sie einen Wert >= 0 ein.\n                  "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.$v.categories.$each[
                                                      index
                                                    ].weight.numeric
                                                      ? _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "form-text text-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                    Bitte geben Sie eine Zahl ein\n                  "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "col-1" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: {
                                              type: "button",
                                              disabled: !_vm.isAdmin(),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.prepareRemove(
                                                  cat,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "trash" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "action-wrapper text-right mt-3" },
                          [
                            _vm.$v.categories.$invalid
                              ? _c("div", { staticClass: "text-muted" }, [
                                  _c(
                                    "small",
                                    {
                                      staticClass:
                                        "text-muted font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        "\n              Bitte beheben Sie alle Fehler um die Einträge zu speichern.\n            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    id: "save-entity",
                                    disabled:
                                      _vm.$v.categories.$invalid ||
                                      _vm.isSaving ||
                                      !_vm.isAdmin(),
                                    type: "submit",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "save" },
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n                Änderungen Speichern"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.categories.length === 0
                  ? _c("div", { staticClass: "mt-3 alert alert-secondary" }, [
                      _vm._v(
                        "\n        Es sind keine Kategorien im System hinterlegt.\n      "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            [
              _c("font-awesome-icon", {
                staticClass: "m-3",
                attrs: { spin: true, icon: "sync" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-modal", { ref: "removeEntity", attrs: { id: "removeEntity" } }, [
        _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
          _c(
            "span",
            { attrs: { id: "cdpRisikoApp.percentage.delete.question" } },
            [_vm._v("Löschen bestätigen")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-body" }, [
          _c("p", { attrs: { id: "gen-delete-percentage-heading" } }, [
            _vm._v(
              "\n        Sind Sie sicher, dass Sie folgende Kategorie löschen möchten?\n      "
            ),
          ]),
          _vm._v(" "),
          _vm.categoryToRemove != null &&
          _vm.categoryToRemove.description != null &&
          _vm.categoryToRemove.weight != null
            ? _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.categoryToRemove.description) +
                    " - Gewichtung: " +
                    _vm._s(_vm.categoryToRemove.weight) +
                    "%\n      "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeDeleteDialog()
                },
              },
            },
            [_vm._v("\n        Abbrechen\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                id: "gen-confirm-delete-percentage",
                "data-cy": "entityConfirmDeleteButton",
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.removeCategory()
                },
              },
            },
            [_vm._v("\n        Löschen\n      ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-flex font-weight-bold mb-2" }, [
      _c("div", { staticClass: "col-1" }, [_c("label")]),
      _vm._v(" "),
      _c("div", { staticClass: "col-5" }, [
        _c("label", [_vm._v("Bezeichnung ")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-5" }, [
        _c("label", [_vm._v("Gewichtung")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }