import { IAppUser } from '@/shared/model/app-user.model';

export interface ICategory {
  id?: number;
  description: string;
  weight: number;
  orderNumber?: number;
  deletedTimestamp?: Date | null;
  user?: IAppUser;
}

export class Category implements ICategory {
  constructor(
    public id?: number,
    public description = null,
    public weight = null,
    public orderNumber?: number,
    public deletedTimestamp?: Date | null,
    public user?: IAppUser
  ) {}
}
