
import { Component, Inject, Vue } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import AlertService from '@/shared/alert/alert.service';
import { minValue, numeric, required } from 'vuelidate/lib/validators';
import { Category, ICategory } from '@/shared/model/category.model';
import CategoryService from '@/entities/category/category.service';
import AccountService from '@/account/account.service';

const validations: any = {
  categories: {
    $each: {
      description: {
        required,
      },
      weight: {
        required,
        numeric,
        min: minValue(0),
      },
    },
  },
};

@Component({
  mixins: [Vue2Filters.mixin],
  validations,
})
export default class CategorySettings extends Vue {
  public isSaving = false;
  public category: ICategory = new Category();
  public isLoading = false;
  public categories: ICategory[] = [];
  public categoryToRemove: ICategory = null;
  public categoryToRemoveIndex: number = null;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('categoryService') private categoryService: () => CategoryService;
  @Inject('accountService') private accountService: () => AccountService;

  public isAdmin() {
    return this.accountService().isAdmin();
  }

  public async mounted(): Promise<void> {
    await this.init();
  }

  public updateOrder(category: ICategory, delta: number) {
    if (category.orderNumber == null) {
      category.orderNumber = 0;
    }
    let newOrderNumber = category.orderNumber + delta;
    //order number is not allowed to be out of bounds of the array
    //0 because order number should not be negative
    newOrderNumber = Math.max(newOrderNumber, 0);
    // -1 because array starts at 0
    newOrderNumber = Math.min(newOrderNumber, this.categories.length - 1);
    //check if other category has the same order number
    const otherCategory = this.categories.find(c => c.orderNumber === newOrderNumber);
    //if other category exists, swap order numbers
    if (otherCategory) {
      otherCategory.orderNumber = category.orderNumber;
    }
    category.orderNumber = newOrderNumber;
    this.categories.sort((a, b) => a.orderNumber - b.orderNumber);
  }

  /**
   * adds new category to list
   */
  public addCategory(): void {
    const newElement = new Category();
    //set order number to last index
    newElement.orderNumber = this.categories.length;
    this.categories.push(newElement);
  }

  /**
   * closes delete dialog
   */
  public closeDeleteDialog(): void {
    (this.$refs.removeEntity as any).hide();
  }

  /**
   * inits delete logic, sets categoryToRemove and categoryToRemoveIndex
   * @param instance category to remove
   * @param index index of category to remove
   */
  public prepareRemove(instance: ICategory, index: number): void {
    this.categoryToRemove = instance;
    this.categoryToRemoveIndex = index;
    if (this.$refs.removeEntity as any) {
      (this.$refs.removeEntity as any).show();
    }
  }

  /**
   * removes item from list or from database if already persisted
   */
  public async removeCategory() {
    //if category is not persisted, remove it from list
    if (!this.categoryToRemove.id && this.categoryToRemoveIndex != null) {
      this.categories.splice(this.categoryToRemoveIndex, 1);
      this.closeDeleteDialog();
      this.categoryToRemove = null;
      this.categoryToRemoveIndex = null;
      this.alertService().showInfo(this, 'Die Kategorie wurde erfolgreich gelöscht ');
      return;
    }
    //if category is persisted, remove it from database
    try {
      await this.categoryService().delete(this.categoryToRemove.id);
      this.categoryToRemove = null;
      this.categoryToRemoveIndex = null;
      this.init().then();
      this.closeDeleteDialog();
      this.alertService().showInfo(this, 'Die Kategorie wurde erfolgreich gelöscht ');
    } catch (error) {
      this.alertService().showHttpError(this, error.response);
    }
  }

  /**
   * saves and updates all percentages in list
   */
  async saveAllCategories() {
    if (!this.categories || this.categories.length === 0) {
      return;
    }
    try {
      this.validateList(this.categories, true);
    } catch (e) {
      this.alertService().showError(this, e.message);
      return;
    }
    try {
      await this.categoryService().saveAll(this.categories);
      this.alertService().showSuccess(this, 'Die Einträge wurden erfolgreich gespeichert.');
      await this.init();
    } catch (e) {
      let message = 'Fehler beim Speichern der Einträge';
      if (e.response?.data?.title) {
        message = e.response.data.title;
      }
      this.alertService().showError(this, message);
    }
  }

  /**
   * returns the error/validation String for given category
   * if the category is valid null is returend
   * @param category
   * @private
   */
  public validationString(category: ICategory): string {
    try {
      this.validateCategory(category);
    } catch (e) {
      return e.message;
    }
    return null;
  }

  /**
   * checks if every percentage is valid,
   * if everything is ok it returns true, if any percentage or the list is invalid it returns false
   * @private
   */
  public isSaveButtonEnabled(): boolean {
    for (const category of this.categories) {
      try {
        this.validateCategory(category);
      } catch (e) {
        return false;
      }
    }
    try {
      this.validateList(this.categories);
    } catch (e) {
      return false;
    }
    return true;
  }

  private async init() {
    this.isLoading = true;
    try {
      this.categories = (await this.categoryService().retrieve({ sort: ['description'] })).data;
      //set order number if not set to index
      this.categories.forEach((c, index) => {
        if (c.orderNumber == null) {
          c.orderNumber = index;
        }
      });
      this.categories.sort((a, b) => a.orderNumber - b.orderNumber);
    } catch (e) {
      this.alertService().showError(this, 'Beim Laden der Prozentsätze ist ein Fehler aufgetreten');
    }
    this.isLoading = false;
  }

  /**
   * validates a percentage
   * must have fields: value
   * -value >=0 && value <= 100
   * @param category
   * @private
   */
  private validateCategory(category: ICategory) {
    if (category.description == null || category.description.trim().length === 0) {
      throw new Error('Es wurde keine Bezeichnung angegeben.');
    }
    const MAX_WEIGHT = 100;
    const MIN_WEIGHT = 0;
    if (category.weight < MIN_WEIGHT || category.weight > MAX_WEIGHT) {
      throw new Error('Es sind nur Gewichtungen zwischen 0 und 100 erlaubt');
    }
  }

  /**
   * validates list of categories
   * @param categories list of categories
   * @param validateSingle check field if every single item should be validated
   * @private
   */
  private validateList(categories: ICategory[], validateSingle = false) {
    if (categories == null) {
      return;
    }
    const valueArr = categories.map(item => item.description);
    const isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });
    if (isDuplicate) {
      throw new Error('Bitte verwenden Sie jede Bezeichnung ausschließlich 1-Mal');
    }
    //if not every single percentage needs to be validated return
    if (!validateSingle) {
      return;
    }
    //validate every single percentage
    for (const category of categories) {
      this.validateCategory(category);
    }
  }
}
